.header{
    height:60px;
    background-color: rgb(24, 23, 23);
    z-index: -1;
    display: flex;
    justify-content: space-between;
    padding:10px;
}
.hlogo img{
    height:100%;
}
.hbuttons{
    padding:15px;
    display: flex;
}
.hbuttons h3{
    color:aliceblue;
    margin-right: 5px;
    margin-top: 0%;
}
.hbuttons a{
    text-decoration: none;
    color: black;
}
.hbuttons a:hover{
    color: aliceblue;
}
.hbuttons>button{
     border:none;
     width:70px;
     height:30px;
     margin-left:4px;
     border-radius: 2px;
     font-family: Arial, Helvetica, sans-serif;
     background-color: rgb(16, 226, 16);
}
.hbuttons>button:hover{
    background-color: rgb(24, 23, 23);
    cursor: pointer;
    color: aliceblue;
}
.home{
    background-position: center;
    background-size: cover;
    background-image: url("./images/168399.jpg");
    min-height: 100%;
    height:100vh;
    z-index: -1;
    position: sticky;
    color: bisque;
}
.home>div{
    width:70%;
    margin: auto;
    text-align: center;
    padding-top: 40vh;
}
.home>div>span{
    color:rgb(24, 23, 23);
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
}
.signupform{
    background-position: center;
    background-size: cover;
    background-color: rgb(24, 23, 23);
    min-height: 100%;
    height:100vh;
    z-index: -1;
    color: aliceblue;
}
.signupform>div{
    width:50%;
    margin: auto;
    padding-top: 20vh;
}
.loginform{
    background-position: center;
    background-size: cover;
    background-color: rgb(24, 23, 23);
    min-height: 100%;
    height:100vh;
    z-index: -1;
    color: aliceblue;
}
.loginform>div{
    width:50%;
    margin: auto;
    padding-top: 20vh;
}
input[type=text],input[type=password], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type=submit] {
    width: 100%;
    background-color: black;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: rgb(24, 23, 23);
  }

  .spinner-1 {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #000 #0000;
    animation: s1 1s infinite;
  }
  @keyframes s1 {to{transform: rotate(.5turn)}}

.profile{
    background-color: rgb(24, 23, 23);
    height: fit-content;
    padding: 20px;
}
.profilecont0{
    width:90%;
    margin:auto;
    padding:10px;
    border: 1px solid rgb(16, 226, 16);
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
}
.profilecont0>button{
   height:30px;
   width:100px;
   background-color: rgb(16, 226, 16);
   border:none;
   color: black;
   margin-top: 5px;
   cursor: pointer;
}
.profilecont0>button:hover{
    background-color: rgb(24, 23, 23);
    color:aliceblue
}
.profilecont1{
    width:90%;
    height: 100vh;
    margin:auto;
    padding:10px;
    /* border: 1px solid rgb(16, 226, 16); */
    border-radius: 5px;
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(3,30%);
    gap:10px;
}
.profilecont1>div{
    border:1px solid green;
    margin-left: 50px;
    height:200px;
    padding: 10px;
}
.popup{
    padding: 30px;
    background-position: center;
    background-size: cover;
    background-image: url("https://wallpaperaccess.com/full/628807.jpg");
    min-height: 100%;
    color:aliceblue;
}

.nftcard{
    border:1px solid green;
    text-align: center;
    color:aliceblue;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.nftcard img{
    width:80%;
    height:80%;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .signupform>div{
        width:90%;
        margin: auto;
        padding-top: 20vh;
    }
    .loginform>div{
        width:90%;
        margin: auto;
        padding-top: 20vh;
    }
    .profilecont1{
        width:90%;
        height: fit-content;
        margin:auto;
        /* border: 1px solid rgb(16, 226, 16); */
        border-radius: 5px;
        display: grid;
        grid-template-columns: repeat(1,100%);
        padding-bottom: 100px;
    }
    .profilecont1>div{
        /* border:1px solid green; */
        height:200px;
        padding: 10px;
        margin-left: 5px;
    }
    .profile{
        background-color: rgb(24, 23, 23);
        height: fit-content;
    }
  }
